import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-soft-token-download',
  templateUrl: './soft-token-download.component.html',
  styleUrls: ['./soft-token-download.component.scss'],
})
export class SoftTokenDownloadComponent implements OnInit {
  toNextStepFlag = false;
  @Output() nextStep: EventEmitter<void> = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {}

  toNextStep() {
    this.nextStep.emit();
  }
}
