<div class="success-container" *ngIf="isErrorFailure">
  <div class="message-container">
    <h2>{{ errorMessage }}</h2>
    <div class="message-content">
      <p>
        Pour toute assistance veuillez contacter le service Cash Management :
      </p>
      <p class="email">{{ cashManagementMail }}</p>
    </div>
  </div>
  <div class="image-container">
    <img src="/assets/images/vector-soft-token-error.svg" alt="" />
  </div>
</div>

<div class="success-container" *ngIf="!isErrorFailure">
  <div class="message-container">
    <h2>{{ errorMessage }}</h2>
    <div class="message-content">
      <p>
        Pour toute assistance veuillez contacter le service Cash Management :
      </p>
      <p class="email">{{ cashManagementMail }}</p>
    </div>
    <div class="message-content">
      <p>Accéder au portail <span>MYBUSINESS</span> via le lien :</p>
      <a [href]="urlPortal">https://mybusiness.sgmaroc.com</a>
    </div>
  </div>
  <div class="image-container">
    <img src="/assets/images/vector-soft-token.svg" alt="" />
  </div>
</div>
