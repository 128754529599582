<div class="onboarding-container">
  <div class="onboarding-label">
    <h2>ONBOARDING MYBUSINESS PASS</h2>
  </div>

  <mat-horizontal-stepper
    [selectedIndex]="currentStep"
    [linear]="true"
    [@.disabled]="true"
    #stepper
  >
    <mat-step [editable]="false" [completed]="authStepCompleteFlag">
      <ng-template matStepLabel>
        <h2>CONFIRMATION N° DE TÉL</h2>
        <p>Confirmez les derniers chiffres du numéro de téléphone affiché.</p>
      </ng-template>
      <app-soft-token-auth
        [responsePhoneNumber]="responsePhoneNumber"
        (nextStep)="authnextStephundler(stepper)"
      ></app-soft-token-auth>
    </mat-step>
    <mat-step [editable]="false" [completed]="downloadStepCompleteFlag">
      <ng-template matStepLabel>
        <h2>TÉLÉCHARGEMENT</h2>
        <p>Téléchargez l’application MyBusiness PASS à partir des Stores.</p>
      </ng-template>

      <app-soft-token-download
        *ngIf="stepper.selectedIndex === 1"
        (nextStep)="downloadNextStephundler(stepper)"
      ></app-soft-token-download>
    </mat-step>
    <mat-step [editable]="false" [completed]="activationStepCompleteFlag">
      <ng-template matStepLabel>
        <h2>ACTIVATION</h2>
        <p>Activez My Business Pass.</p>
      </ng-template>

      <app-soft-token-activation
        [responsePhoneNumber]="responsePhoneNumber"
        *ngIf="stepper.selectedIndex === 2"
      ></app-soft-token-activation>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="st-tel-info">
    <p>
      Pour toute assistance veuillez contacter le service Cash Management :
      <span class="email">{{ cashManagementMail }}</span>
    </p>
  </div>
</div>
