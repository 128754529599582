<div class="contact-us-content">
  <app-footer-elem
    image="/assets/images/help.svg"
    title="Assistance Cash Management"
    description="Lundi au Vendredi de 8h00 à 16h30"
  ></app-footer-elem>
  <app-footer-elem
    image="/assets/images/mail.svg"
    title="Adresse électronique"
    description="cashmanagement-maroc.assistance@socgen.com"
  ></app-footer-elem>
</div>
<div class="copyright-text">
  <p>Société Générale Maroc - 2023</p>
</div>
