import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
  @Output() timerEnded: EventEmitter<void> = new EventEmitter<void>();
  @Input() startTimer: boolean = false;
  @Input() minutesInit: number = 3;
  @Input() secondsInit: number = 0;
  minutes: number;
  seconds: number;
  timerSubscription: any;
  startTime = new Date().getTime();
  endTime = new Date().getTime();

  constructor() {}

  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (document.hidden) {
      this.startTime = new Date().getTime();
      //console.log('minutes:' + this.minutesInit + '  Hidden startTime: ' + this.startTime);
    } else {
      this.endTime = new Date().getTime();
      //console.log('minutes:' + this.minutesInit + '  Showen endTime: ' + this.endTime);
      const timeDifference = this.endTime - this.startTime;
      /*console.log(
        'minutes:' +
          this.minutesInit +
          '  Showen timeDifference: ' +
          timeDifference
      );*/
      const timeDifferenceInSeconds = Math.floor(timeDifference / 1000);
      /*console.log(
        'minutes:' +
          this.minutesInit +
          '  Showen timeDifferenceInSeconds: ' +
          timeDifferenceInSeconds
      );*/
      const diff = this.minutes * 60 + this.seconds - timeDifferenceInSeconds;
      //console.log('minutes:' + this.minutesInit + '  Showen diff: ' + diff);
      if (diff < 0) {
        this.minutes = 0;
        this.seconds = 0;
      } else {
        this.minutes = Math.floor(diff / 60);
        this.seconds = diff % 60;
      }
      /*console.log(
        'minutes:' +
          this.minutesInit +
          '  Showen min: ' +
          this.minutes +
          ' sec:' +
          this.seconds
      );*/
    }
  }

  ngOnInit(): void {
    this.minutes = this.minutesInit;
    this.seconds = this.secondsInit;
    if (this.startTimer) {
      this.start();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*if (changes.startTimer && !changes.startTimer.firstChange) {
      if (changes.startTimer.currentValue) {
        this.start();
      } else {
        this.stop();
      }
    }*/
  }

  ngOnDestroy(): void {
    this.stop();
  }

  start(): void {
    this.timerSubscription = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          this.stop();
          this.timerEnded.emit(); // Emit event when timer ends
          return;
        }
        this.minutes--;
        this.seconds = 59;
      } else {
        this.seconds--;
      }
    }, 1000);
  }

  stop(): void {
    if (this.timerSubscription) {
      clearInterval(this.timerSubscription);
    }
  }

  resetTimer(): void {
    this.stop();
    this.minutes = this.minutesInit; // Reset minutes to initial value
    this.seconds = this.secondsInit; // Reset seconds to initial value
  }
}
