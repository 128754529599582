import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TimerComponent } from '../../shared-module/timer/timer.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { NgForm } from '@angular/forms';
import { ResponsePhoneNumber } from 'src/app/models/response-phone-number';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-soft-token-auth',
  templateUrl: './soft-token-auth.component.html',
  styleUrls: ['./soft-token-auth.component.scss'],
})
export class SoftTokenAuthComponent implements OnInit {
  numberOfOtpSent: number;
  cashManagementMail = environment.cashManagementMail;
  constructor(
    private userService: UserService,
    private activatdRoute: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}

  @Input() responsePhoneNumber: ResponsePhoneNumber;
  uuid: string;
  phoneNumber = '';
  userId: string;
  softTokenActivationStatus: string;
  otpCode: string;
  otpSendingLimit: number = 3;
  otpTapingLimit: number = 5;
  otpTimer: number = 5;
  otpTimerMinutes: number;
  otpTimerSeconds: number;

  smsSent = false;
  startTimer = false;
  timerFlag = false;

  isErrorSendSms = false;
  errorSendSms = '';
  errorSendSmsDefaultMSG =
    'Une erreur est survenue. Merci de bien vouloir réessayer.';

  resendSmsBtnFlag = false;
  sendSmsBtnFlag = false;

  validOtpBtnFlag = false;

  isErrorValidateOtp = false;
  errorValidateOtp = '';

  toNextStepFlag = false;
  loaderDisplay = false;

  errorOtpSendingLimitDefaultMSG =
    "Vous avez atteint la limite d'envoi de SMS, veuillez contacter l'assistance cash management.";

  @Output() nextStep: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(TimerComponent) timer: TimerComponent;

  ngOnInit() {
    //http://localhost:4200/soft-token/on-boarding?uuid=fa827014-3f34-4f7e-a060-895c0c5304af
    this.uuid = this.responsePhoneNumber.uuid;
    this.phoneNumber = this.responsePhoneNumber.phoneNumber;
    this.userId = this.responsePhoneNumber.userId;
    this.softTokenActivationStatus = this.responsePhoneNumber.status;
    this.numberOfOtpSent = this.responsePhoneNumber.numberOfOtpSent;
    this.otpSendingLimit =
      this.responsePhoneNumber.otpSendingLimit - this.numberOfOtpSent;
    this.otpTapingLimit = this.responsePhoneNumber.otpTapingLimit;
    this.otpTimer = this.responsePhoneNumber.otpTimer;
    this.otpTimerMinutes = Math.floor(this.otpTimer / 60);
    this.otpTimerSeconds = this.otpTimer % 60;
  }

  sendSms() {
    this.sendSmsBtnFlag = true;
    this.loaderDisplay = true;
    this.userService.sendOTP(this.userId).subscribe(
      () => {
        this.loaderDisplay = false;
        this.sendSmsBtnFlag = false;
        this.smsSent = true;
        this.restartTimer();
        this.resendSmsBtnFlag = true;
        this.otpSendingLimit--;
      },
      (error) => {
        this.loaderDisplay = false;
        this.sendSmsBtnFlag = false;
        this.isErrorSendSms = true;
        this.errorSendSms = this.errorSendSmsDefaultMSG;
        this.openSnackBar(this.errorSendSms, 'Fermer');
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['my-snackbar-error'],
      duration: 3000, // Adjust duration as needed
      horizontalPosition: 'center', // Adjust position as needed
      verticalPosition: 'bottom', // Adjust position as needed});
    });
  }

  reSendSms(timer) {
    if (this.otpSendingLimit > 0) {
      this.loaderDisplay = true;
      this.userService.sendOTP(this.userId).subscribe(
        () => {
          this.loaderDisplay = false;
          this.otpTapingLimit = this.responsePhoneNumber.otpTapingLimit;
          this.otpSendingLimit--;
          this.restartTimer(timer);
          this.resendSmsBtnFlag = true;
          this.validOtpBtnFlag = true;
        },
        (error) => {
          this.loaderDisplay = false;
          this.isErrorSendSms = true;
          this.errorSendSms = this.errorSendSmsDefaultMSG;
          this.openSnackBar(this.errorSendSms, 'Fermer');
        }
      );
    }
  }
  restartTimer(timer?: TimerComponent): void {
    this.timerFlag = true;
    if (timer) {
      timer.resetTimer(); // Call the resetTimer method of the TimerComponent
      timer.start(); // Start the timer again
    } else {
      this.startTimer = true;
    }
  }
  handleTimerEnd() {
    this.timerFlag = false;
    if (!this.toNextStepFlag) {
      if (this.otpSendingLimit > 0) this.resendSmsBtnFlag = false;
      else this.openSnackBar(this.errorOtpSendingLimitDefaultMSG, 'Fermer');
    }
  }

  validateOtp() {
    this.userService
      .validateOTP(this.userId, this.otpCode, this.uuid)
      .subscribe(
        (res) => {
          if (res.otpValid) {
            this.toNextStepFlag = true;
            this.userService.lastOtpValid = this.otpCode;
          } else {
            this.hundleInvalidOtp();
          }
        },
        (error) => {
          this.errorValidateOtp =
            'Une erreur est survenue. Merci de bien vouloir réessayer.';
          this.openSnackBar(this.errorValidateOtp, 'Fermer');
        }
      );
  }
  onOtpChange(event: any) {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/\D/g, '').slice(0, 6); // Remove non-numeric characters and limit to 6 characters
    this.otpCode = event.target.value;

    this.validOtpBtnFlag =
      this.otpTapingLimit > 0 && this.otpCode.length == 6 ? true : false;
  }
  toNextStep() {
    if (this.toNextStepFlag) {
      this.timer.resetTimer();
      this.nextStep.emit();
    }
  }
  hundleInvalidOtp() {
    this.otpTapingLimit--;
    this.isErrorValidateOtp = true;
    this.errorValidateOtp = `Le code de validation saisi est invalide. Il vous reste ${this.otpTapingLimit} tentatives`;
    if (this.otpTapingLimit == 0) {
      this.validOtpBtnFlag = false;
      this.errorValidateOtp =
        'Le code de validation saisi est invalide. Il ne vous reste plus aucune tentative.';
    }

    this.openSnackBar(this.errorValidateOtp, 'Fermer');
  }
}
