import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soft-token-header',
  templateUrl: './soft-token-header.component.html',
  styleUrls: ['./soft-token-header.component.scss']
})
export class SoftTokenHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
