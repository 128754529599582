<div class="breadcrumb">
  <span (click)="toDashboard()" class="item has-arrow">Accueil</span>
</div>

<div class="activate-soft-token">
  <div class="activate-container">
    <div class="left-content">
      <h1>Activation My Business Pass Mobile</h1>
      <p>
        Votre numéro de téléphone est bien le :
        <span class="red">{{ phoneNumber }}</span>
      </p>
      <p>
        <span class="dark">Point important : </span>Si le numéro de téléphone
        affiché est incorrect, veuillez contacter le support :
        cashmanagement-maroc.assistance@socgen.com
      </p>

      <p>
        <span>Téléchargement de l'application :</span> Pensez à télécharger
        l’application MYBUSINESS PASS, depuis Google Play ou App Store, avant de
        commencer le processus d’activation.
      </p>
    </div>

    <div class="right-content">
      <div class="operators-container">
        <div *ngFor="let operator of operators; let i = index">
          <div
            [ngClass]="i == indexs ? 'operator active' : 'operator'"
            (click)="selectOperator(operator, i)"
          >
            <div class="logo-container">
              <img src="{{ operator.icone }}" alt="opertaor" />
            </div>
            <span>{{ operator.libelle }}</span>

            <div class="checkbox">
              <span class="fake-check">
                <img src="/assets/images/ic_check.svg" alt="check operator" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="ctas">
        <button type="button" class="btn download" (click)="downloadGuide()">
          <span>Télécharger le guide utilisateur</span>
        </button>
        <button
          type="button"
          [ngClass]="check == 'true' ? 'btn send hide' : 'btn send'"
          [disabled]="activateCss"
          (click)="activateSoftToken()"
        >
          <span>Envoyer le code PIN</span>
        </button>
        <div
          [ngClass]="check == 'true' ? 'sent send' : 'sent send hide'"
          *ngIf="send"
        >
          <span>Envoyé</span>

          <span (click)="activateSoftToken()">Code non-envoyé ?</span>
        </div>
      </div>
    </div>
  </div>
</div>
