import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-soft-token-message',
  templateUrl: './soft-token-message.component.html',
  styleUrls: ['./soft-token-message.component.scss'],
})
export class SoftTokenMessageComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() isErrorFailure: boolean;

  urlPortal = environment.urlPortal;
  cashManagementMail = environment.cashManagementMail;
  constructor() {}

  ngOnInit() {}
}
