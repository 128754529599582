import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SoftTokenNewOnboardingRoutingModule } from './soft-token-new-onboarding-routing.module';
import { SoftTokenDashboardComponent } from './soft-token-dashboard/soft-token-dashboard.component';
import { SoftTokenAuthComponent } from './soft-token-auth/soft-token-auth.component';
import { SoftTokenDownloadComponent } from './soft-token-download/soft-token-download.component';
import { SoftTokenVideoComponent } from './soft-token-video/soft-token-video.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SoftTokenActivationComponent } from './soft-token-activation/soft-token-activation.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../shared-module/shared.module';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SoftTokenHeaderComponent } from './soft-token-header/soft-token-header.component';
import { SoftTokenOnboardingComponent } from './soft-token-onboarding/soft-token-onboarding.component';
import { SoftTokenMessageComponent } from './soft-token-message/soft-token-message.component';
import { MatIconModule, MatTooltipModule } from '@angular/material';

@NgModule({
  declarations: [
    SoftTokenDashboardComponent,
    SoftTokenAuthComponent,
    SoftTokenDownloadComponent,
    SoftTokenVideoComponent,
    SoftTokenActivationComponent,
    SoftTokenHeaderComponent,
    SoftTokenOnboardingComponent,
    SoftTokenMessageComponent,
  ],
  imports: [
    CommonModule,
    SoftTokenNewOnboardingRoutingModule,
    MatStepperModule,
    MatSnackBarModule,
    SharedModule,
    FormsModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class SoftTokenNewOnboardingModule {}
