import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ExchangeRate } from '../models/exchangeRate';
import { Review } from '../models/review';
import { ResponsePhoneNumber } from '../models/response-phone-number';
import { ResponseCronto } from '../models/response-cronto';
import { ResponseOtpValidate } from '../models/response-otp-validate';
import { Application } from '../models/application';
import { map, switchMap } from 'rxjs/operators';
import { UserRolesDTO } from '../models/UserRolesDto';

import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable()
export class UserService {
  public lastOtpValid: string;
  constructor(private http: HttpClient) {}
  public user: User;

  public getUserRoles(
    clientId: number,
    username: string
  ): Observable<UserRolesDTO> {
    return this.http.get<UserRolesDTO>(
      `${environment.apiUrl}/users/clients/${clientId}/roles?username=${username}`,
      httpOptions
    );
  }

  public getUserRolesByCustomerNumber(
    customerNumber: number,
    username: string
  ): Observable<UserRolesDTO> {
    return this.http.get<UserRolesDTO>(
      `${environment.apiUrl}/users/clients/roles?username=${username}&customerNumber=${customerNumber}`,
      httpOptions
    );
  }

  public getUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/users/me`, httpOptions);
  }

  public getAllApplications(): Observable<Application[]> {
    return this.http.get<Application[]>(
      `${environment.apiUrl}/applications`,
      httpOptions
    );
  }

  public getExchangeRate(): Observable<Array<ExchangeRate>> {
    return this.http.get<Array<ExchangeRate>>(
      `${environment.apiUrl}/users/exchange/rate`,
      httpOptions
    );
  }

  public saveReview(review: Review): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/users/review`,
      review,
      httpOptions
    );
  }

  public getPhoneNumber(uuid: string): Observable<ResponsePhoneNumber> {
    return this.http.get<ResponsePhoneNumber>(
      `${environment.apiUrl}/soft-token/user?uuid=${uuid}`,
      httpOptions
    );
  }

  public sendOTP(userId: string): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/otp?userId=${userId}`,
      {},
      httpOptions
    );
  }

  public validateOTP(
    userId: string,
    otp: string,
    uuid: string
  ): Observable<ResponseOtpValidate> {
    return this.http.get<ResponseOtpValidate>(
      `${environment.apiUrl}/otp/validate?userId=${userId}&otp=${otp}&uuid=${uuid}`,
      httpOptions
    );
  }

  public stepRegsitrationToken(uuid: string): Observable<ResponseCronto> {
    return this.http.post<ResponseCronto>(
      `${environment.apiUrl}/soft-token/registration?uuid=${uuid}`,
      {},
      httpOptions
    );
  }

  public stepOnlineRegsitrationToken(
    uuid: string,
    lastOtpValid: string
  ): Observable<ResponseCronto> {
    return this.http.post<ResponseCronto>(
      `${environment.apiUrl}/soft-token/online/registration?uuid=${uuid}&otp=${lastOtpValid}`,
      {},
      httpOptions
    );
  }

  public stepActivationToken(
    uuid: string,
    deviseCode: string
  ): Observable<ResponseCronto> {
    return this.http.post<ResponseCronto>(
      `${environment.apiUrl}/soft-token/add-device`,
      { uuid: uuid, codeDevice: deviseCode },
      httpOptions
    );
  }

  public getAllApps(): Observable<Application[]> {
    return this.http.get<Application[]>(
      `${environment.apiUrl}/clients`,
      httpOptions
    );
  }

  public log(appName: string): Observable<string> {
    return this.http.get<string>(
      `${environment.apiUrl}/applications/currentApp/${appName}`,
      httpOptions
    );
  }

  public acceptCGU(): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/users/cgu`, httpOptions);
  }

  public hideSoftToken(): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/users/hide-soft-token`,
      httpOptions
    );
  }

  public numberSoftToken(): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/users/number-soft-token`,
      httpOptions
    );
  }

  endAllStepsVerification(username: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrl}/soft-token/is-onboarded?username=${username}`,
      httpOptions
    );
  }

  endAllStepsVerificationInterval(username: string): Observable<any> {
    return interval(2000).pipe(
      // Fetch data every 2 seconds
      switchMap(() => this.endAllStepsVerification(username))
    );
  }
}
