<!-------------------------------- Loader management --------------------------------------->
<div class="loader" *ngIf="loaderDisplay">
  <img class="loader-icon" src="/assets/images/Ellipsis-2s-80px.gif" alt="" />
</div>
<!----------------------------------------------------------------------------------------->
<div class="st-tel-container">
  <div class="st-tel-container-b1">
    <div class="st-tel-val" *ngIf="!smsSent">
      <div class="st-tel-acions">
        <p class="st-tel-text">
          Veuillez confirmer votre numéro de téléphone afin de vous authentifier
        </p>
        <p class="st-tel-box">
          Votre N° de téléphone: <span>{{ phoneNumber }}</span>
        </p>
        <button
          class="st-sms-send"
          mat-button
          (click)="sendSms()"
          [disabled]="sendSmsBtnFlag"
        >
          ENVOYER LE SMS
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.432"
            height="18.315"
          >
            <g data-name="Groupe 21480">
              <g
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                data-name="Icon feather-send"
              >
                <path d="m17.371 1.061-9.077 9.077" data-name="Tracé 12607" />
                <path
                  d="m17.367 1.061-5.772 16.5-3.3-7.427L.867 6.837Z"
                  data-name="Tracé 12608"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div class="st-tel-info">
        <p>
          Veuillez contacter le service Assistance Management dans le cas<br />
          où le N° du téléphone serait erroné:<br />
          <span> {{ cashManagementMail }} </span>
        </p>
      </div>
    </div>
    <div class="st-tel-otp" *ngIf="smsSent">
      <div class="st-tel-otp-action">
        <div class="st-tel-otp-action1">
          <p>
            SMS envoyé
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <g data-name="Groupe 21256">
                <g data-name="Groupe 20712">
                  <path
                    fill="none"
                    stroke="#14b354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.8"
                    d="m14.252 4.369-7.63 7.633-3.47-3.469"
                    data-name="Icon feather-check"
                  />
                </g>
              </g>
            </svg>
          </p>
          <button
            class="resend-btn"
            [disabled]="resendSmsBtnFlag"
            (click)="reSendSms(timer)"
          >
            <span>RENVOYER LE SMS</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13">
              <g data-name="Group 21500">
                <g
                  fill="none"
                  stroke="#ee5b6f"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  data-name="Icon feather-arrow-up-right"
                >
                  <path d="m3.433 9.568 6.135-6.135" data-name="Path 12611" />
                  <path d="M3.433 3.433h6.135v6.135" data-name="Path 12612" />
                </g>
              </g>
            </svg>
          </button>
          <span class="seperator"></span>
          <div class="st-tel-timer">
            <app-timer
              [secondsInit]="otpTimerSeconds"
              [minutesInit]="otpTimerMinutes"
              [startTimer]="startTimer"
              (timerEnded)="handleTimerEnd()"
              #timer
            ></app-timer>
          </div>
        </div>
        <div class="st-tel-otp-action2">
          <p class="st-tel-attempt">
            (<span>{{ otpSendingLimit }}</span> restants)
          </p>
        </div>
      </div>
      <div class="st-tel-otp-val">
        <p>Veuillez saisir ci-dessous le code reçu par SMS</p>

        <div class="st-tel-otp-form">
          <input
            type="text"
            placeholder="- - - - - -"
            [(ngModel)]="otpCode"
            pattern="[0-9]{6,6}"
            (input)="onOtpChange($event)"
            name="otpCode"
            required
            class="st-tel-otp-input"
          />
          <button
            *ngIf="!toNextStepFlag"
            class="ok-btn"
            (click)="validateOtp()"
            [disabled]="!validOtpBtnFlag || !timerFlag"
          >
            <span>OK</span>
          </button>
          <div *ngIf="toNextStepFlag" class="success-btn">
            <svg
              viewBox="91.2564 22.223 126.2751 124.2"
              width="50"
              height="50"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="path circle"
                fill="none"
                stroke="#14b354"
                stroke-width="4"
                stroke-miterlimit="10"
                cx="154.394"
                cy="84.323"
                r="50"
                transform="matrix(0.9999999999999999, 0, 0.016708001494407654, 0.9999999999999999, -1.408851981163025, 0)"
              />
              <polyline
                class="path check"
                fill="none"
                stroke="#14b354"
                stroke-width="4"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="176.014 67.142 144.796 100.303 130.417 85.77"
                transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="st-tel-container-b2">
    <svg xmlns="http://www.w3.org/2000/svg" width="447.573" height="226.118">
      <g data-name="Groupe 21622">
        <path
          fill="#3f3d56"
          d="M300.598 90.762h-1.122V216.17H71.221v1.122h229.377Z"
          data-name="Tracé 12643"
        />
        <g data-name="Groupe 21620">
          <path
            fill="#3f3d56"
            d="M171.608 217.291H43.179V90.761h1.122v125.408h127.307Z"
            data-name="Tracé 12644"
          />
          <g data-name="Groupe 21619" transform="translate(0 2.695)">
            <path
              fill="#3f3d56"
              d="M87.204 41.56h-.961V15.236A15.236 15.236 0 0 0 71.007 0H15.236A15.236 15.236 0 0 0-.004 15.236v144.416a15.236 15.236 0 0 0 15.236 15.236h55.771a15.236 15.236 0 0 0 15.236-15.236V60.298h.961Z"
              data-name="Tracé 12647"
            />
            <path
              fill="#fff"
              d="M83.003 16.131v144.205a11.379 11.379 0 0 1-11.378 11.38H15.582a11.378 11.378 0 0 1-11.379-11.378V16.131A11.377 11.377 0 0 1 15.578 4.755h6.8a5.4 5.4 0 0 0 5.005 7.445h31.951a5.4 5.4 0 0 0 5.005-7.445h7.28a11.377 11.377 0 0 1 11.384 11.374Z"
              data-name="Tracé 12648"
            />
            <rect
              width="15.012"
              height="4.449"
              fill="#3f3d56"
              data-name="Rectangle 18003"
              rx="2"
              transform="translate(36.095 89.713)"
            />
            <rect
              width="51.311"
              height="1.907"
              fill="#3f3d56"
              data-name="Rectangle 18004"
              rx=".953"
              transform="translate(17.946 55.076)"
            />
            <rect
              width="51.311"
              height="1.907"
              fill="#3f3d56"
              data-name="Rectangle 18005"
              rx=".953"
              transform="translate(17.946 61.114)"
            />
            <rect
              width="51.311"
              height="1.907"
              fill="#3f3d56"
              data-name="Rectangle 18006"
              rx=".953"
              transform="translate(17.946 67.151)"
            />
            <rect
              width="51.311"
              height="1.907"
              fill="#3f3d56"
              data-name="Rectangle 18007"
              rx=".953"
              transform="translate(17.946 73.189)"
            />
            <rect
              width="51.311"
              height="1.907"
              fill="#3f3d56"
              data-name="Rectangle 18008"
              rx=".953"
              transform="translate(17.946 79.227)"
            />
            <circle
              cx="16.159"
              cy="16.159"
              r="16.159"
              fill="#ee5b6f"
              data-name="Ellipse 724"
              transform="translate(23.029 119.868)"
            />
            <path
              fill="#3f3d56"
              d="M43.95 148.49a20.26 20.26 0 1 1 20.26-20.26 20.26 20.26 0 0 1-20.26 20.26Zm0-39.782a19.523 19.523 0 1 0 19.523 19.523 19.523 19.523 0 0 0-19.523-19.523Z"
              data-name="Tracé 12649"
            />
          </g>
          <path
            fill="#d0cde1"
            d="M82.724 210.414h15.703v15.703H82.724z"
            data-name="Rectangle 18009"
          />
          <path
            fill="#3f3d56"
            d="M107.962 217.144H88.894v-19.068h19.068Zm-18.239-.829h17.41v-17.41h-17.41Z"
            data-name="Tracé 12650"
          />
        </g>
        <path
          fill="#2f2e41"
          d="M424.752 166.512H176.82V6.85A6.858 6.858 0 0 1 183.67 0h234.232a6.858 6.858 0 0 1 6.85 6.85Z"
          data-name="Tracé 12715"
        />
        <path
          fill="#dfdfdf"
          d="M445.389 183.472H155.566a2.173 2.173 0 0 1-1.132-4.028l22.592-13.783h247.809l.071.044 21.654 13.759a2.174 2.174 0 0 1-1.163 4.009Z"
          data-name="Tracé 12716"
        />
        <path
          fill="#fff"
          d="M183.322 10.178h234.643v145.875H183.322z"
          data-name="Rectangle 18021"
        />
        <path
          fill="#2f2e41"
          d="M427.012 174.711H175.408l12.438-10.091H415.14Z"
          data-name="Tracé 12717"
        />
        <path
          fill="#2f2e41"
          d="M360.297 181.494H240.995l4.523-6.783h110.256Z"
          data-name="Tracé 12718"
        />
        <path
          fill="#dfdfdf"
          d="M370.97 67.863h-12.43a1.959 1.959 0 0 1 0-3.918h12.43a1.959 1.959 0 0 1 0 3.918Z"
          data-name="Tracé 12719"
        />
        <path
          fill="#dfdfdf"
          d="M385.263 67.863h-5.8a1.959 1.959 0 1 1 0-3.918h5.8a1.959 1.959 0 1 1 0 3.918Z"
          data-name="Tracé 12720"
        />
        <path
          fill="#ee5b6f"
          d="M246.523 69.358h-37.831a3.5 3.5 0 1 1 0-7h37.831a3.5 3.5 0 0 1 0 7Z"
          data-name="Tracé 12721"
        />
        <path
          fill="#dfdfdf"
          d="M226.013 78.701h-18.231a2.137 2.137 0 1 1 0-4.274h18.231a2.137 2.137 0 0 1 0 4.274Z"
          data-name="Tracé 12722"
        />
        <path
          fill="#dfdfdf"
          d="M326.518 87.134h-119.2a2.137 2.137 0 1 1 0-4.274h119.2a2.137 2.137 0 1 1 0 4.274Z"
          data-name="Tracé 12723"
        />
        <path
          fill="#dfdfdf"
          d="M316.265 92.833H207.323a2.137 2.137 0 1 1 0-4.274h108.938a2.137 2.137 0 1 1 0 4.274Z"
          data-name="Tracé 12724"
        />
        <g data-name="Groupe 21621">
          <path
            fill="#d0cde1"
            d="M238.071 210.415h15.703v15.703h-15.703z"
            data-name="Rectangle 18010"
          />
          <path
            fill="#3f3d56"
            d="M228.537 217.145h19.068v-19.068h-19.068Zm18.239-.829h-17.41v-17.41h17.41Z"
            data-name="Tracé 12651"
          />
        </g>
      </g>
    </svg>
  </div>
</div>

<div class="st-tel-actions">
  <div
    class="tooltip-icon"
    matTooltip="Veuillez vérifier que vous avez une bonne connexion internet. Il se peut que les appels perturbent votre parcours d'onboarding."
    matTooltipPosition="right"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="2.279" height="11.829">
      <g fill="#ee5b6f" data-name="Groupe 21684">
        <path d="M2.279 11.829h-2.28V5.008h2.279Z" data-name="Tracé 11889" />
        <path
          d="M1.952 1.946A1.147 1.147 0 0 1 .328.326a1.147 1.147 0 0 1 1.624 1.62Z"
          data-name="Tracé 11890"
        />
      </g>
    </svg>
  </div>
  <div>
    <button
      class="next-btn"
      (click)="toNextStep()"
      [disabled]="!toNextStepFlag"
    >
      Suivant
    </button>
  </div>
</div>
