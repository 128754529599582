<div class="st-activation-container">
  <div class="st-activation-steps">
    <p class="activation-title">
      Pour activer <span>MY BUSINESSPASS</span>, veuillez suivre les
      instructions ci-dessous:
    </p>
    <ul>
      <li>
        <img src="/assets/images/1.png" />
        <p>
          Ouvrir l'application <span>MYBUSINESS PASS</span> depuis le téléphone.
        </p>
      </li>
      <li>
        <img src="/assets/images/2.png" />
        <p>Cliquer sur <span>SCANNER LE QR code</span>.</p>
      </li>
      <li>
        <img src="/assets/images/3.png" />
        <p>Scanner le <span>QR CODE</span> affiché à droite.</p>
      </li>
      <li>
        <img src="/assets/images/4.png" />
        <p>Configurer votre CODE PIN sur l'application Mobile.</p>
        <div
          class="tooltip-icon"
          matTooltip="Ce code sert à vous identifier sur My Business Pass afin de générer votre OTP"
          matTooltipPosition="right"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="2.279" height="11.829">
            <g fill="#ee5b6f" data-name="Groupe 21684">
              <path
                d="M2.279 11.829h-2.28V5.008h2.279Z"
                data-name="Tracé 11889"
              />
              <path
                d="M1.952 1.946A1.147 1.147 0 0 1 .328.326a1.147 1.147 0 0 1 1.624 1.62Z"
                data-name="Tracé 11890"
              />
            </g>
          </svg>
        </div>
      </li>
    </ul>
  </div>
  <div class="st-activation-qrcode">
    <div *ngIf="!activationSuccess" class="st-activation-scan-img">
      <p *ngIf="generateCrontoBtnFlag">
        Veuillez scanner le <span>QR CODE</span> ci-dessous
      </p>

      <div *ngIf="generateCrontoBtnFlag" class="qr-code-container">
        <img
          *ngIf="crontoMessage && !crontoMessageLoaderDisplay"
          id="crontoMessage"
          src="{{ crontoMessage }}"
        />
        <div *ngIf="crontoMessageLoaderDisplay" class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div *ngIf="!generateCrontoBtnFlag">
        <button
          class="st-qr-generate"
          [disabled]="generateCrontoBtnFlag"
          (click)="generateCrontoBtnHandler(timer)"
        >
          <img src="/assets/images/regenerer-qrcode.svg" alt="" />
          Regénérer le QR code
        </button>
      </div>
      <div class="st-activation-timer">
        <app-timer
          [secondsInit]="qrCodeTimerSeconds"
          [minutesInit]="qrCodeTimerMinutes"
          [startTimer]="startTimer"
          (timerEnded)="handleTimerEnd()"
          #timer
        ></app-timer>
      </div>
    </div>
    <div *ngIf="activationSuccess" class="st-activation-success">
      <div class="success-btn">
        <svg
          viewBox="91.2564 22.223 126.2751 124.2"
          width="117"
          height="117"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#14b354"
            stroke-width="3"
            stroke-miterlimit="10"
            cx="154.394"
            cy="84.323"
            r="60"
            transform="matrix(0.9999999999999999, 0, 0.016708001494407654, 0.9999999999999999, -1.408851981163025, 0)"
          />
          <polyline
            class="path check"
            fill="none"
            stroke="#14b354"
            stroke-width="3"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="176.014 67.142 144.796 100.303 130.417 85.77"
            transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
          />
        </svg>
      </div>
      <div class="success-content" *ngIf="activationSuccess">
        <h2>MyBusiness Pass est activé avec succès</h2>
        <p>
          Félicitations pour l'activation de
          <span>MyBusiness Pass</span>,<br />
          Vous pouvez désormais accéder au portail MyBusiness en cliquant
          directement<br />
          sur le bouton <span>TERMINER</span>.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="st-activation-actions">
  <div
    class="tooltip-icon"
    matTooltip="Veuillez vérifier que vous avez une bonne connexion internet. Il se peut que les appels perturbent votre parcours d'onboarding."
    matTooltipPosition="right"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="2.279" height="11.829">
      <g fill="#ee5b6f" data-name="Groupe 21684">
        <path d="M2.279 11.829h-2.28V5.008h2.279Z" data-name="Tracé 11889" />
        <path
          d="M1.952 1.946A1.147 1.147 0 0 1 .328.326a1.147 1.147 0 0 1 1.624 1.62Z"
          data-name="Tracé 11890"
        />
      </g>
    </svg>
  </div>
  <button
    class="next-btn"
    (click)="endOnBoarding()"
    [ngClass]="{ 'end-btn-disabled': !activationSuccess }"
  >
    TERMINER
  </button>
</div>
