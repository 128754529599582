import { Component, Input, OnInit } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material';
import { ResponsePhoneNumber } from 'src/app/models/response-phone-number';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-soft-token-onboarding',
  templateUrl: './soft-token-onboarding.component.html',
  styleUrls: ['./soft-token-onboarding.component.scss'],
})
export class SoftTokenOnboardingComponent implements OnInit {
  currentStep = 0;
  // Define complete flag of step
  authStepCompleteFlag = false;
  downloadStepCompleteFlag = false;
  activationStepCompleteFlag = false;
  @Input() responsePhoneNumber: ResponsePhoneNumber;
  cashManagementMail = environment.cashManagementMail;

  ngOnInit() {}

  authnextStephundler(stepper: MatHorizontalStepper) {
    this.authStepCompleteFlag = true;
    setTimeout(() => {
      stepper.next();
    }, 4);
  }

  downloadNextStephundler(stepper) {
    this.downloadStepCompleteFlag = true;
    setTimeout(() => {
      stepper.next();
    }, 4);
  }
}
