<div class="video-soft-token-container">
  <div class="video-soft-token-label">
    <h2>TUTORIAL</h2>
  </div>
  <div class="video-soft-token-wrapper">
    <div *ngIf="!videoStarted" class="video-soft-token-action">
      <div class="video-soft-token-content">
        <h1>COMMENT ACTIVER <span>MYBUSINESS</span> PASS ?</h1>
        <hr />
        <h5>
          Vous pouvez regarder la vidéo pour vous expliquer comment faire votre
          onboarding facilement
        </h5>
      </div>
      <button class="start-btn-soft" (click)="startVideo()">
        <img src="/assets/images/start.svg" alt="" />
      </button>
      <div class="shared_progress_container">
        <app-progress [value]="getDeg()">
          <img
            (click)="handlestatusVideo()"
            *ngIf="!videoplayer.nativeElement.paused"
            src="/assets/images/pause.svg"
            alt=""
          />
          <img
            (click)="handlestatusVideo()"
            *ngIf="videoplayer.nativeElement.paused"
            src="/assets/images/start.svg"
            alt=""
          />
        </app-progress>
      </div>

      <!-- <div class="circle-border" [ngStyle]="styledObject">
        <div class="circle">
          <img
            (click)="handlestatusVideo()"
            *ngIf="!videoplayer.nativeElement.paused"
            src="/assets/images/pause.svg"
            alt=""
          />
          <img
            (click)="handlestatusVideo()"
            *ngIf="videoplayer.nativeElement.paused"
            src="/assets/images/start.svg"
            alt=""
          />
        </div>
      </div> -->
    </div>
    <button
      class="back-btn-soft-token"
      (click)="endVideo()"
      *ngIf="videoStarted"
    >
      <span class="icon">
        <img src="/assets/images/arrow-back-soft.svg" alt="" />
      </span>
      <span class="text">Retour</span>
    </button>

    <div class="backdrop video-soft-token">
      <video
        loop
        muted
        autoplay
        oncanplay="this.play()"
        onloadedmetadata="this.muted = true"
        width="100%"
        height="100%"
        controls
        #videoPlayer
      ></video>
    </div>
  </div>
  <button class="next-btn" (click)="startOnBoarding()">
    Commencer l'OnBoarding
  </button>
</div>
