import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatHorizontalStepper } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ResponsePhoneNumber } from 'src/app/models/response-phone-number';
import { UserService } from 'src/app/service/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-soft-token-dashboard',
  templateUrl: './soft-token-dashboard.component.html',
  styleUrls: ['./soft-token-dashboard.component.scss'],
})
export class SoftTokenDashboardComponent implements OnInit {
  responsePhoneNumber: ResponsePhoneNumber;
  uuid: string;
  isErrorFetchPhoneNum = false;
  errorFetchPhoneNum = '';
  errorFetchPhoneNumDefaultMSG =
    "Une erreur technique est survenue, veuillez contacter l'assistance cash management.";

  softTokenActivationStatus: string;
  isLocked: boolean;
  isActif: boolean;
  isError = false;
  isErrorFailure = true;
  errorMessage: string;
  numberOfOtpSent: number;
  otpSendingLimit: number;

  softTokenVideo: boolean = environment.softTokenVideo;
  loaderDisplay: boolean = true;
  userId: string;

  constructor(
    private userService: UserService,
    private activatdRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatdRoute.queryParams.subscribe((params) => {
      this.uuid = params['uuid'];
      this.userService.getPhoneNumber(this.uuid).subscribe(
        (res) => {
          this.responsePhoneNumber = res;
          this.responsePhoneNumber.uuid = this.uuid;
          this.softTokenActivationStatus = this.responsePhoneNumber.status;
          this.isLocked = this.responsePhoneNumber.isLocked;
          this.isActif = this.responsePhoneNumber.isActif;
          this.isError = false;
          this.errorMessage = '';
          this.userId = this.responsePhoneNumber.userId;

          this.numberOfOtpSent = this.responsePhoneNumber.numberOfOtpSent;
          this.otpSendingLimit = this.responsePhoneNumber.otpSendingLimit;

          if (
            this.softTokenActivationStatus.toLocaleUpperCase() === 'EXPIRED'
          ) {
            this.isError = true;
            this.errorMessage = 'Votre lien a déjà expiré.';
          } else if (this.isLocked) {
            this.isError = true;
            this.errorMessage = 'Votre compte est bloqué.';
          } else if (!this.isActif) {
            this.isError = true;
            this.errorMessage = 'Votre compte est désactivé. ';
          } else if (
            this.softTokenActivationStatus.toLocaleUpperCase() === 'ACTIVATED'
          ) {
            this.isError = true;
            this.isErrorFailure = false;
            this.errorMessage = 'Ce Digipass a déjà été activé auparavant.';
          } else if (this.numberOfOtpSent == this.otpSendingLimit) {
            this.isError = true;
            this.errorMessage =
              "Vous avez atteint le nombre maximum d'envoi de sms.";
          } else {
            this.isError = false;
          }
          this.loaderDisplay = false;
        },
        (error) => {
          this.isError = true;
          this.errorMessage = this.errorFetchPhoneNumDefaultMSG;
          this.loaderDisplay = false;
        }
      );
    });
  }
}
