import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-soft-token-video',
  templateUrl: './soft-token-video.component.html',
  styleUrls: ['./soft-token-video.component.scss'],
})
export class SoftTokenVideoComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  timerSubscription: any;
  ratio: number = 0;
  urlPortal = environment.urlPortal;
  urlVideo = this.urlPortal + 'videos/softToken.mp4';

  constructor() {}

  @Output() toNextStep = new EventEmitter<void>();
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  videoStarted: boolean = false;

  ngAfterViewInit() {
    this.lazyLoadVideo();
  }

  lazyLoadVideo() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = this.videoplayer.nativeElement;
          video.src = this.urlVideo;
          observer.unobserve(video);
        }
      });
    });

    observer.observe(this.videoplayer.nativeElement);
  }

  ngOnInit() {
    this.timerSubscription = interval(10).subscribe(() => {
      this.ratio =
        (this.videoplayer.nativeElement.currentTime /
          this.videoplayer.nativeElement.duration) *
        100;
    });
  }

  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

  startOnBoarding() {
    this.toNextStep.emit();
  }

  handlestatusVideo() {
    if (this.videoplayer.nativeElement.paused) {
      this.videoplayer.nativeElement.play();
    } else {
      this.videoplayer.nativeElement.pause();
    }
  }

  startVideo() {
    this.videoStarted = true;
    this.videoplayer.nativeElement.muted = false;
  }

  endVideo() {
    this.videoStarted = false;
    this.videoplayer.nativeElement.muted = true;
  }

  getDeg() {
    return this.ratio;
  }
}
