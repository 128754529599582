<svg class="shared_progress__svg" width="40" height="40" viewBox="0 0 40 40">
  <circle
    class="shared_progress__meter"
    cx="20"
    cy="20"
    [attr.r]="radius"
    stroke-width="2"
  />
  <circle
    class="shared_progress__value"
    [style.strokeDasharray]="circumference"
    [style.strokeDashoffset]="dashoffset"
    cx="20"
    cy="20"
    [attr.r]="radius"
    stroke-width="2"
  />
</svg>

<div class="shared_progress__content">
  <ng-content></ng-content>
</div>
