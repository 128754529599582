<div class="st-download-container">
  <div class="st-download-steps">
    <p class="download-title">
      Pour télécharger l'application <span>MYBUSINESS PASS</span>, veuillez
      suivre les instructions ci-dessous:
    </p>
    <ul>
      <li>
        <img src="/assets/images/1.png" />
        <p>
          Allez sur <span>App Store</span> ou <span>Play Store</span> depuis
          votre téléphone.
        </p>
      </li>
      <li>
        <img src="/assets/images/2.png" />
        <p>
          Cliquer sur installer ou obtenir l'application
          <span>MYBUSINESS PASS</span>
        </p>
      </li>
    </ul>
  </div>
  <div class="st-download-images">
    <img src="/assets/images/download-art.svg" />
  </div>
</div>
<div class="st-download-actions">
  <div
    class="tooltip-icon"
    matTooltip="Veuillez vérifier que vous avez une bonne connexion internet. Il se peut que les appels perturbent votre parcours d'onboarding."
    matTooltipPosition="right"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="2.279" height="11.829">
      <g fill="#ee5b6f" data-name="Groupe 21684">
        <path d="M2.279 11.829h-2.28V5.008h2.279Z" data-name="Tracé 11889" />
        <path
          d="M1.952 1.946A1.147 1.147 0 0 1 .328.326a1.147 1.147 0 0 1 1.624 1.62Z"
          data-name="Tracé 11890"
        />
      </g>
    </svg>
  </div>
  <div class="st-download-actions-a1">
    <mat-slide-toggle [(ngModel)]="toNextStepFlag"
      >J’AI INSTALLÉ L’APPLICATION</mat-slide-toggle
    >
    <button
      class="next-btn"
      (click)="toNextStep()"
      [disabled]="!toNextStepFlag"
    >
      Suivant
    </button>
  </div>
</div>
