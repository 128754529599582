<div style="overflow-y: auto">
  <app-soft-token-header></app-soft-token-header>
  <img
    *ngIf="loaderDisplay; else noLoaderBlock"
    class="upload-icon"
    src="/assets/images/Ellipsis-2s-80px.gif"
    alt=""
  />
  <ng-template #noLoaderBlock>
    <app-soft-token-message
      *ngIf="isError; else noErrorBlock"
      [errorMessage]="errorMessage"
      [isErrorFailure]="isErrorFailure"
    ></app-soft-token-message>
    <ng-template #noErrorBlock>
      <app-soft-token-video
        *ngIf="softTokenVideo; else onboardingBlock"
        (toNextStep)="softTokenVideo = false"
      ></app-soft-token-video>
      <ng-template #onboardingBlock>
        <app-soft-token-onboarding
          [responsePhoneNumber]="responsePhoneNumber"
          *ngIf="!softTokenVideo && !isError && responsePhoneNumber"
        ></app-soft-token-onboarding>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
