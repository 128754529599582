import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseCronto } from 'src/app/models/response-cronto';
import { UserService } from 'src/app/service/user.service';
import { TimerComponent } from '../../shared-module/timer/timer.component';
import { environment } from '../../../../environments/environment';
import { ResponsePhoneNumber } from 'src/app/models/response-phone-number';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-soft-token-activation',
  templateUrl: './soft-token-activation.component.html',
  styleUrls: ['./soft-token-activation.component.scss'],
})
export class SoftTokenActivationComponent implements OnInit, OnDestroy {
  @ViewChild(TimerComponent) timer: TimerComponent;
  @Input() responsePhoneNumber: ResponsePhoneNumber;
  userId: string;

  toNextStepFlag: boolean = false;
  uuid: string;
  crontoMessage: string;
  crontoMessageLoaderDisplay = false;

  isErrorFetchCronto = false;
  errorFetchCrontoMessage = '';
  errorFetchCrontoMessageDefaultMSG =
    'Une erreur est survenue. Merci de bien vouloir réessayer.';

  isErrorEndAllStepsValidation = false;
  errorEndAllStepsValidationMessage = '';
  errorEndAllStepsValidationMessageDefaultMSG =
    'Une erreur est survenue. Merci de bien vouloir réessayer.';
  activationSuccess: boolean;

  qrCodeTimer: number = 1;
  qrCodeTimerSeconds: number;
  qrCodeTimerMinutes: number;

  startTimer = false;
  crontoSendingLimit: number = 3;
  generateCrontoBtnFlag: boolean = true;

  urlPortal = environment.urlPortal;
  endAllStepsVerificationInterval: number;

  constructor(
    private userService: UserService,
    private activatdRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatdRoute.queryParams.subscribe((params) => {
      this.uuid = params['uuid'];
    });
    this.userId = this.responsePhoneNumber.userId;
    this.crontoSendingLimit = this.responsePhoneNumber.qrCodeDisplayingLimit;
    this.qrCodeTimer = this.responsePhoneNumber.qrCodeTimer;
    this.qrCodeTimerMinutes = Math.floor(this.qrCodeTimer / 60);
    this.qrCodeTimerSeconds = this.qrCodeTimer % 60;
    this.fetchCronto();

    this.endAllStepsVerificationInterval = setInterval(() => {
      this.userService.endAllStepsVerification(this.userId).subscribe(
        (res) => {
          if (res) {
            this.timer.resetTimer();
            this.activationSuccess = true;
            this._snackBar.dismiss();
            if (this.endAllStepsVerificationInterval)
              clearInterval(this.endAllStepsVerificationInterval);
          }
        },
        (error) => {}
      );
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.endAllStepsVerificationInterval)
      clearInterval(this.endAllStepsVerificationInterval);
  }

  fetchCronto() {
    this.crontoMessageLoaderDisplay = true;
    this.userService
      .stepOnlineRegsitrationToken(this.uuid, this.userService.lastOtpValid)
      .subscribe(
        (res) => {
          this.crontoSendingLimit--;
          this.crontoMessageLoaderDisplay = false;
          this.crontoMessage = this.getImagePngBase64(res);
          this.restartTimer();
        },
        (error) => {
          this.crontoMessageLoaderDisplay = false;
          this.generateCrontoBtnFlag = false;

          this.isErrorFetchCronto = true;
          this.errorFetchCrontoMessage = this.errorFetchCrontoMessageDefaultMSG;
          this.openSnackBar(this.errorFetchCrontoMessage, 'Fermer');
        }
      );
  }

  restartTimer(): void {
    this.generateCrontoBtnFlag = true;
    if (this.timer) {
      this.timer.resetTimer(); // Call the resetTimer method of the TimerComponent
      this.timer.start(); // Start the timer again
    } else {
      this.startTimer = true;
    }
  }

  getImagePngBase64(res: ResponseCronto): string {
    return 'data:' + res.data + ';base64,' + res.base64;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['my-snackbar-error'],
      duration: 4000, // Adjust duration as needed
      horizontalPosition: 'center', // Adjust position as needed
      verticalPosition: 'bottom', // Adjust position as needed});
    });
  }

  handleTimerEndWithFalseReturn() {
    //toggleBtn.toggle();
    this.activationSuccess = false;
    if (this.crontoSendingLimit <= 0) {
      this.generateCrontoBtnFlag = true;
      this._snackBar.open(
        "Vous avez dépassé la limite de régénération de QrCode. Veuillez recommencer le processus d'onboarding.",
        'Fermer',
        {
          panelClass: ['my-snackbar-error'],
          horizontalPosition: 'center', // Adjust position as needed
          verticalPosition: 'bottom', // Adjust position as needed});
        }
      );
    } else {
      this.generateCrontoBtnFlag = false;
    }
  }
  handleTimerEnd() {
    this.userService.endAllStepsVerification(this.userId).subscribe(
      (res) => {
        if (res) {
          this.timer.resetTimer();
          this.activationSuccess = true;
          this.toNextStepFlag = true;
        } else {
          this.handleTimerEndWithFalseReturn();
        }
      },
      (error) => {
        this.handleTimerEndWithFalseReturn();
      }
    );
  }
  generateCrontoBtnHandler() {
    if (this.crontoSendingLimit > 0) {
      this.fetchCronto();
      this.generateCrontoBtnFlag = true;
    } else {
      this.generateCrontoBtnFlag = true;
      this.openSnackBar(
        "Vous avez dépassé la limite de régénération de QrCode. Veuillez recommencer le processus d'onboarding.",
        'Fermer'
      );
    }
  }

  endAllStepsVerification(toggleBtn) {
    if (this.toNextStepFlag) {
      this.userService.endAllStepsVerification(this.userId).subscribe(
        (res) => {
          if (res) {
            this.timer.resetTimer();
            this.activationSuccess = true;
          } else {
            setTimeout(() => {
              toggleBtn.toggle();
            }, 300);
            //toggleBtn.toggle();
            this.activationSuccess = false;
            this.errorEndAllStepsValidationMessage =
              "L'activation de MyBusiness Pass n'a pas été complétée avec succès. Veuillez essayer à nouveau.";
            this.openSnackBar(this.errorEndAllStepsValidationMessage, 'Fermer');
          }
        },
        (error) => {
          setTimeout(() => {
            toggleBtn.toggle();
          }, 300);
          this.activationSuccess = false;
          this.isErrorEndAllStepsValidation = true;
          this.errorEndAllStepsValidationMessage =
            this.errorEndAllStepsValidationMessageDefaultMSG;

          this.openSnackBar(this.errorEndAllStepsValidationMessage, 'Fermer');
        }
      );
    }
  }

  redirectToUrl(url: string): void {
    window.location.href = url;
  }

  endOnBoarding() {
    if (!this.activationSuccess) {
      this.errorEndAllStepsValidationMessage =
        "L'activation de MyBusiness Pass n'a pas été complétée. Veuillez essayer à nouveau.";
      this.openSnackBar(this.errorEndAllStepsValidationMessage, 'Fermer');
    } else {
      this.redirectToUrl(this.urlPortal);
    }
  }
}
